export const varsityRoster = [
  {
    name: "Haiden Ortega",
    number: "4",
    position: "OF",
    height: "5`7''",
    weight: "115lbs",
    handed: "R/R",
    year: "Senior",
    yearAbbr: "Sr.",
  },
  {
    name: "Liam Childs",
    number: "8",
    position: "3B, P",
    height: "5`11''",
    weight: "170lbs",
    handed: "R/R",
    year: "Senior",
    yearAbbr: "Sr.",
  },
  {
    name: "Orlando Hurtado-Delgado",
    number: "9",
    position: "OF",
    height: "5`10''",
    weight: "150lbs",
    handed: "R/R",
    year: "Senior",
    yearAbbr: "Sr.",
  },

  {
    name: "Tai'Shaun Charleston",
    number: "18",
    position: "OF",
    height: "6`1''",
    weight: "210lbs",
    handed: "R/R",
    year: "Senior",
    yearAbbr: "Sr.",
  },

  {
    name: "Pablo Vielma",
    number: "23",
    position: "2B, SS",
    height: "5`7''",
    weight: "145lbs",
    handed: "R/R",
    year: "Senior",
    yearAbbr: "Sr.",
  },

  {
    name: "Nathaniel Butler",
    number: "52",
    position: "1B",
    height: "5`9''",
    weight: "235lbs",
    handed: "R/R",
    year: "Senior",
    yearAbbr: "Sr.",
  },

  {
    name: "Preston Lampley",
    number: "n/a",
    position: "2B",
    height: "5`10''",
    weight: "160lbs",
    handed: "R/R",
    year: "Junior",
    yearAbbr: "Jr.",
  },

  {
    name: "Scotty Dewey",
    number: "3",
    position: "SS/P",
    height: "6`",
    weight: "215lbs",
    handed: "R/R",
    year: "Junior",
    yearAbbr: "Jr.",
  },
  {
    name: "Thompson Antonio",
    number: "n/a",
    position: "OF",
    height: "5`11''",
    weight: "185lbs",
    handed: "R/R",
    year: "Junior",
    yearAbbr: "Jr.",
  },
  {
    name: "Weber Jason",
    number: "7",
    position: "OF",
    height: "6`",
    weight: "153lbs",
    handed: "R/R",
    year: "Junior",
    yearAbbr: "Jr.",
  },
  {
    name: "Uriel Hernandez",
    number: "13",
    position: "C",
    height: "5`9''",
    weight: "190lbs",
    handed: "R/R",
    year: "Junior",
    yearAbbr: "Jr.",
  },
  {
    name: "Matthew Jimenez",
    number: "10",
    position: "2B",
    height: "5`9''",
    weight: "115lbs",
    handed: "R/R",
    year: "Sophomore",
    yearAbbr: "Soph.",
  },

  {
    name: "Spencer Brill",
    number: "1",
    position: "Utility",
    height: "5`7''",
    weight: "115lbs",
    handed: "R/R",
    year: "Sophomore",
    yearAbbr: "Soph.",
  },
];
